import React, { useContext, useEffect, useState } from "react";
import Header from "../dashboard/components/header";
import HeaderDropdown from "../../components/header_dropdown";
import Table from "../../components/ajonjolib/table/table";
import Filters from "../dashboard/components/filters";
import MainContext from "../../context";
import { openModal } from "../../components/ajonjolib/toasts/toast/toast";
import HeartModal from "./modals/heart_modal";
import SleepModal from "./modals/sleep_modal";
import { Button } from "../../components/ajonjolib/inputs/ajonjolinput";
import ExportModal from "../configuration/company/modals/export";
import api from "../../api";
import { timestampToStr } from "../../utils/date";
import { FiltersContext } from "../../filtersContext";
import { useWebSocket } from "../../wsContext";
import { FaceFromRisk } from "../../utils/faces";

export default function Detailed() {
    const [filters, setFilters] = useContext(FiltersContext);
    const [open, setOpen] = useState(true);
    const [rows, setRows] = useState([]);
    const [searchText, setSearchText] = useState('');

    const socket = useWebSocket();

    useEffect(() => {
        if (!socket) return;
        socket.onmessage = (event) => {
            const data = JSON.parse(event.data);
            const message = data.message;
            const project_id = data.project;
            const project_group_id = data.project_group;
            const enterprise_id = data.enterprise;

            const enterprise_filter = filters.company === enterprise_id || filters.company === -1;
            const project_group_filter = filters.project_group === project_group_id || filters.project_group === -1;
            const project_filter = filters.project === project_id || filters.project === -1;
            const now = new Date();
            const start_date = new Date(filters.date);
            start_date.setHours(0, 0, 0, 0);
            const end_date = new Date(filters.date);
            end_date.setHours(23, 59, 59, 999);
            const date_filter = now >= start_date && now <= end_date;
            console.log('filters', { enterprise_filter, project_group_filter, project_filter, date_filter });
            if (enterprise_filter && project_group_filter && project_filter && date_filter) {
                retrieveData();
            }
        };
    }, [socket, filters]);

    const getDetailed = (id, modal, row) => {
        let parsed_filters = '';
        for (const [key, value] of Object.entries(filters)) {
            if (key === 'date') {
                parsed_filters += `${key}=${timestampToStr(value)}&`;
                continue;
            }
            parsed_filters += `${key}=${value}&`;
        }
        api.get(`employee-health-data-details/${id}/?${parsed_filters}`).then((response) => {
            if (response.status !== 200) return;
            let data = response.data;
            const found = rows.find((row) => row.employee.id === id);

            openModal(modal, {
                ...data,
                user_data: found,
                date: filters.date,
                employee: row.employee
            });
        });
    }

    const cols = [
        { title: 'ID', key: 'employee.id' },
        {
            title: 'Nombres y Apellidos', key: 'employee', code: (row, data) => {
                return data?.vNombres + " " + data?.vApellidos;
            }
        },
        {
            title: 'Total de Sueño', key: 'risk_level', code: (row, data) => {
                const face_id = (data === "none" || data === "low") ? 1 : (data === "mid") ? 2 : 3;
                const sleep_duration = row.sleep_effective_duration;
                const hours = Math.floor(sleep_duration / 60);
                const fract_minutes = sleep_duration % 60;
                return (
                    <div className={'d-flex align-items-center'}>
                        <FaceFromRisk risk={data} scale={0.75} onClick={() => {
                            getDetailed(row.employee.id, 'sleep_modal', row);
                        }} />
                        <div className={'ps-2'} style={{ color: '#5863B4', fontSize: '20px', fontWeight: 600 }}>{hours}h {fract_minutes < 10 ? "0" + fract_minutes : fract_minutes}m</div>
                    </div>
                )
            }
        },
        {
            title: 'Eficiencia Sueño', key: 'sleep_efficiency', code: (row, data) => {
                const val = parseInt(row.sleep_effective_duration / row.sleep_duration * 100);
                return (<div className={'ps-2'} style={{ color: '#5863B4', fontSize: '20px', fontWeight: 600 }}>{isNaN(val) ? 0 : val}%</div>)
            }
        },
        {
            title: 'Promedio FC', key: 'hr_average', code: (row, data) => {
                return (
                    <div className={'d-flex align-items-center'}>
                        <img role={'button'} alt={'heart'} src={'/icons/heart.png'} width={'28px'} onClick={() => {
                            getDetailed(row.employee.id, 'heart_modal', row);
                        }} />
                        <div className={'ps-2'} style={{ color: '#5863B4', fontSize: '20px', fontWeight: 600 }}>{data ? data : '-'}</div>
                    </div>
                )
            }
        },
        {
            title: 'Batería', key: 'battery_level', code: (row, data) => {
                const percentage = parseInt(data);
                const battery_id = percentage > 70 ? 1 : percentage > 50 ? 2 : percentage > 20 ? 3 : 4;
                return (
                    <div className={'d-flex align-items-center'}>
                        <img alt={'battery'} src={`/icons/battery/${battery_id}.png`} width={'14px'} />
                        <div className={'ps-2'}>{data ? data : 0}%</div>
                    </div>
                )
            }
        },
        {
            title: 'Sincronización', header: (title) => {
                return (
                    <div className={'d-flex justify-content-center flex-column align-items-center'}>
                        <div>{title}</div>
                        <div className={'d-flex justify-content-center align-items-center w-100'} style={{ color: '#FFF' }}>
                            <div className={'d-flex justify-content-center align-items-center'} style={{ backgroundColor: '#5863B4', borderRadius: '5px 5px 0 0', width: '107px', height: '23px', fontSize: '11px' }}>Úlitmo Registro</div>
                        </div>
                    </div>
                )
            }, key: 'last_sync', code: (row, data) => {
                let value = new Date(data * 1000).toLocaleString('es-es');
                if (data === 0 || data === "0") {
                    value = "No Sincronizó"
                }
                return (<div style={{ color: '#797D86', fontWeight: 300 }}>{value}</div>)
            }
        },
    ]

    const getParsedFilters = () => {
        let parsed_filters = '';
        for (const [key, value] of Object.entries(filters)) {
            if (key === 'guard') continue
            if (key === 'date') {
                parsed_filters += `${key}=${timestampToStr(value)}&`;
                continue;
            }
            parsed_filters += `${key}=${value}&`;
        }
        return parsed_filters
    }

    const retrieveData = () => {
        let parsed_filters = getParsedFilters();

        api.get(`employee-health-data/?${parsed_filters}`).then((response) => {
            if (response.status !== 200) return;
            setRows(response.data);
        });
    }

    const { setSettings } = useContext(MainContext);
    useEffect(() => {
        document.title = "Sleeptech - Detalle";
        setSettings((prev) => {
            return {
                ...prev, sidebar: 2
            }
        });

        retrieveData();
    }, [setSettings, filters]);

    const onSearch = (text) => {
        setSearchText(text);
    }

    const filteredRows = rows.filter((row) => {
        const text = searchText.toLowerCase();
        const fullName = row.employee?.vNombres + ' ' + row.employee?.vApellidos;
        const sleep_duration = row.sleep_duration;
        const hours = Math.floor(sleep_duration / 60);
        const fract_minutes = sleep_duration % 60;
        const sleep_text = hours + 'h ' + (fract_minutes < 10 ? "0" + fract_minutes : fract_minutes) + 'm';
        const sleep_effective_duration = parseInt(row.sleep_effective_duration / row.sleep_duration * 100);
        const sleep_effective_text = (isNaN(sleep_effective_duration) ? 0 : sleep_effective_duration) + '%';
        const hr_average_text = (row.hr_average ? row.hr_average : '-').toString();
        const battery_level = parseInt(row.battery_level);
        const battery_text = (battery_level ? battery_level : 0) + '%';
        const last_sync = new Date(row.last_sync * 1000).toLocaleString('es-es');
        const last_sync_text = row.last_sync === 0 || row.last_sync === "0" ? "No Sincronizó" : last_sync;
        const turn = row.turn?.isDay ? 'Día' : 'Noche';
        const active = row.device?.active
        const assigned = row.device ? true : false;
        let deviceStatus = assigned ? 'Asignado' : 'No Asignado';
        if (assigned && active == false) {
            deviceStatus = 'Desactivada';
        }
        return row.id.toString().includes(text) ||
            (fullName && fullName.toLowerCase().includes(text)) ||
            (sleep_text && sleep_text.toLowerCase().includes(text)) ||
            (sleep_effective_text && sleep_effective_text.toLowerCase().includes(text)) ||
            (hr_average_text && hr_average_text.toLowerCase().includes(text)) ||
            (battery_text && battery_text.toLowerCase().includes(text)) ||
            (last_sync_text && last_sync_text.toLowerCase().includes(text))
    })

    return (
        <div>
            <ExportModal title={'Detalle Usuario'} allowDownloadTemplate={false} />
            <SleepModal />
            <HeartModal />
            <Header title={'Detalle Usuario'}>
                <div>
                    <div className={'searchBox'}>
                        <div>
                            <img src={'/icons/search.png'} alt={'search'} width={'auto'} height={'24px'} />
                        </div>
                        <input type={'text'} placeholder={'Buscar...'}
                            onChange={(e) => {
                                onSearch(e.target.value);
                            }
                            }
                        />
                    </div>
                </div>
                <Button variant={'primary'} name={'Exportar'} icon={'/icons/export.png'} onSubmit={() => {
                    openModal('export_modal', {
                        url: 'summary/user-detail/export/',
                        query: getParsedFilters(),
                    })
                }} />
            </Header>
            <div className={'d-flex'}>
                <div style={{ width: '100%', marginRight: '20px' }}>
                    <HeaderDropdown name={'Detalle de Empleados'} setOpen={setOpen} open={open} />
                    <div className={'mb-3'} />
                    {open && <Table cols={cols} rows={filteredRows} />}
                </div>
                <div style={{
                    width: '310px', height: '100%'
                }}>
                    <Filters filters={filters} setFilters={setFilters} type={'detailed'} />
                </div>
            </div>
        </div>
    )
}