export function FaceFromRisk({ risk, scale = 1, onClick }) {
    let FaceComponent;
    if (risk === "low") {
        FaceComponent = GreenFace;
    } else if (risk === "mid") {
        FaceComponent = YellowFace;
    } else if (risk === "high") {
        FaceComponent = RedFace;
    } else if (risk === "critical") {
        FaceComponent = BlueFace;
    } else {
        FaceComponent = GreyFace;
    }

    return (
        <div onClick={onClick}>
            <FaceComponent scale={scale} />
        </div>
    );
}

export function GreenFace(
    {
        scale = 1
    }
) {
    return (
        <div className={'d-flex justify-content-center'} style={{ transform: `scale(${scale})` }}>
            <svg width={"40"} height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="20" cy="20" r="20" fill="#B3C739" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.8374 22.9299C12.4507 22.6616 13.1654 22.9413 13.4337 23.5546C14.0096 24.8707 16.1744 27.4747 19.798 27.4747C24.0768 27.4747 26.2163 24.8163 26.7683 23.5546C27.0366 22.9413 27.7513 22.6616 28.3646 22.9299C28.9779 23.1982 29.2576 23.9129 28.9893 24.5262C28.1271 26.4969 25.2161 29.899 19.798 29.899C14.9367 29.899 12.0511 26.4424 11.2127 24.5262C10.9444 23.9129 11.2241 23.1982 11.8374 22.9299Z" fill="white" />
                <circle cx="13.6263" cy="16.3636" r="2.22222" fill="white" />
                <circle cx="26.5556" cy="16.3636" r="2.22222" fill="white" />
            </svg>
        </div>
    )
}

export function YellowFace(
    {
        scale = 1
    }

) {
    return (
        <div className={'d-flex justify-content-center'} style={{ transform: `scale(${scale})` }}>
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="20" cy="20" r="20" fill="#EEBB07" />
                <circle cx="13.6263" cy="16.3636" r="2.22222" fill="white" />
                <circle cx="26.5556" cy="16.3636" r="2.22222" fill="white" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11 25.6566C11 24.9871 11.5427 24.4445 12.2121 24.4445H27.9697C28.6391 24.4445 29.1818 24.9871 29.1818 25.6566C29.1818 26.326 28.6391 26.8687 27.9697 26.8687H12.2121C11.5427 26.8687 11 26.326 11 25.6566Z" fill="white" />
            </svg>
        </div>
    )
}

export function RedFace({

    scale = 1
}) {
    return (
        <div className={'d-flex justify-content-center'} style={{ transform: `scale(${scale})` }}>
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="20" cy="20" r="20" fill="#F66565" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M28.3647 29.7971C27.7514 30.0654 27.0366 29.7857 26.7683 29.1724C26.1925 27.8562 24.0276 25.2522 20.4041 25.2522C16.1252 25.2522 13.9858 27.9107 13.4338 29.1724C13.1654 29.7857 12.4507 30.0654 11.8374 29.7971C11.2241 29.5287 10.9444 28.814 11.2128 28.2007C12.0749 26.2301 14.9859 22.828 20.4041 22.828C25.2654 22.828 28.151 26.2846 28.9893 28.2007C29.2576 28.814 28.978 29.5287 28.3647 29.7971Z" fill="white" />
                <circle cx="13.6263" cy="16.3636" r="2.22222" fill="white" />
                <circle cx="26.5556" cy="16.3636" r="2.22222" fill="white" />
            </svg>
        </div>
    )
}

export function GreyFace({
    scale = 1
}) {
    return (
        <div className={'d-flex justify-content-center'} style={{ transform: `scale(${scale})` }}>
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M20 40C31.0457 40 40 31.0457 40 20C40 8.95435 31.0457 0 20 0C8.9543 0 0 8.95435 0 20C0 31.0457 8.9543 40 20 40ZM15.8487 16.3635C15.8487 17.5908 14.8538 18.5858 13.6265 18.5858C12.3992 18.5858 11.4043 17.5908 11.4043 16.3635C11.4043 15.1362 12.3992 14.1414 13.6265 14.1414C14.8538 14.1414 15.8487 15.1362 15.8487 16.3635ZM28.7778 16.3635C28.7778 17.5908 27.7829 18.5858 26.5556 18.5858C25.3283 18.5858 24.3334 17.5908 24.3334 16.3635C24.3334 15.1362 25.3283 14.1414 26.5556 14.1414C27.7829 14.1414 28.7778 15.1362 28.7778 16.3635Z" fill="#A4A4A4" />
            </svg>

        </div>
    )
}

export function BlueFace({
    scale = 1
}) {
    return (
        <div className={'d-flex justify-content-center'} style={{ transform: `scale(${scale})` }}>
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM9.6935 17.9829C9.21328 17.5027 9.21328 16.7241 9.6935 16.2439L11.4325 14.5049L9.69351 12.7659C9.21328 12.2856 9.21328 11.5071 9.69351 11.0268C10.1737 10.5466 10.9523 10.5466 11.4325 11.0268L13.1716 12.7658L14.9106 11.0268C15.3908 10.5466 16.1694 10.5466 16.6496 11.0268C17.1299 11.507 17.1299 12.2856 16.6496 12.7658L14.9106 14.5049L16.6496 16.2439C17.1299 16.7241 17.1299 17.5027 16.6496 17.983C16.1694 18.4632 15.3908 18.4632 14.9106 17.983L13.1716 16.2439L11.4325 17.9829C10.9523 18.4632 10.1737 18.4632 9.6935 17.9829ZM26.9218 27.3149C27.2003 27.8932 27.9423 28.1568 28.579 27.9039C29.2157 27.6509 29.5061 26.977 29.2275 26.3988C28.3572 24.5922 25.3614 21.3333 20.3146 21.3333C14.6897 21.3333 11.6675 24.5409 10.7725 26.3988C10.4939 26.977 10.7843 27.6509 11.421 27.9039C12.0577 28.1568 12.7997 27.8932 13.0782 27.3149C13.6513 26.1254 15.8724 23.6189 20.3146 23.6189C24.0765 23.6189 26.324 26.074 26.9218 27.3149ZM23.0268 16.2439C22.5466 16.7241 22.5466 17.5027 23.0268 17.983C23.5071 18.4632 24.2857 18.4632 24.7659 17.983L26.5049 16.2439L28.2439 17.983C28.7242 18.4632 29.5028 18.4632 29.983 17.983C30.4632 17.5028 30.4632 16.7242 29.983 16.244L28.2439 14.5049L29.983 12.7659C30.4632 12.2856 30.4632 11.507 29.983 11.0268C29.5028 10.5466 28.7242 10.5466 28.2439 11.0268L26.5049 12.7659L24.7659 11.0269C24.2856 10.5466 23.5071 10.5466 23.0268 11.0269C22.5466 11.5071 22.5466 12.2857 23.0268 12.7659L24.7659 14.5049L23.0268 16.2439Z" fill="#3D4266" />
            </svg>

        </div>
    )
}