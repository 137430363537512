import React from "react";
import styles from './index.module.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";

export default function TimePicker({ onChange, value }) {
    return (
        <div className={styles.container}>
            <FontAwesomeIcon icon={faClock} className={styles.inputIcon} />
            <input aria-label="Time" type="time"
                value={value} onChange={(event) => {
                    if (onChange) {
                        onChange(event.target.value);
                    }
                }} />
        </div>
    )
}