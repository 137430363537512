import React, { useEffect, useState } from "react";
import styles from './modals.module.css'
import dashboardStyles from '../../../../screens/dashboard/components/filters/index.module.css';
import { eventBus } from "../../../../components/ajonjolib/toasts/toast/toast";
import Modal from "../../../../components/ajonjolib/modal/modal/modal";
import { Label, Select, Text } from "../../../../components/ajonjolib/inputs/ajonjolinput";
import Switch from "../../../../components/ajonjolib/switch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faFile } from "@fortawesome/free-solid-svg-icons";
import api from "../../../../api";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export default function ExportProjectModal({ onSuccess, company }) {
    const [isOpen, setIsOpen] = React.useState(false);
    const [form, setForm] = React.useState({});
    const [projectGroups, setProjectGroups] = useState([]);
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        api.get('project-groups/?subenterprise=' + company?.id).then((res) => {
            if (res?.status !== 200) {
                setProjectGroups([]);
                return;
            }
            setProjectGroups(res.data);
        });
    }, [isOpen]);

    useEffect(() => {
        api.get('projects/?project_group=' + form?.project_group).then((res) => {
            if (res?.status !== 200) {
                setForm({ ...form, project: undefined });
                setProjects([]);
                return;
            }
            setProjects(res.data);
        }
        );
    }, [form.project_group]);

    useEffect(() => {
        eventBus.subscribe('export_project_modal_open', (rec_data) => {
            setIsOpen(true);

        });
        eventBus.subscribe('export_project_modal_close', (data) => { setIsOpen(false) });
    }, []);

    const submit = () => {
        const start_date = form.start_date?.toISOString().split('T')[0];
        const end_date = form.end_date?.toISOString().split('T')[0];
        api.get(`reports/project/?project_id=${form.project}&start_date=${start_date}&end_date=${end_date}`, {
            responseType: 'blob'
        }).then((response) => {
            if (response?.status !== 200) return;
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url
            link.setAttribute('download', 'reporte.pdf');
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        })

    }

    return (
        <div>
            {isOpen &&
                <Modal close={() => setIsOpen(false)} title={'Proyecto'} desc={'Exportar'}
                    // Set width to 200
                    style={{ width: '160px' }}
                >
                    <div className={'d-flex align-items-center w-100 justify-content-between mt-4'}>
                        <div style={{ flexBasis: '100%' }}>
                            <Label>Grupo de Proyecto</Label>
                            <Select value={form['project_group']} options={projectGroups?.map((x) => {
                                return {
                                    value: x.id,
                                    name: x.name ?? ''
                                }
                            })} onChange={(val) => {
                                if (val[0] === form['project_group']) return;
                                setForm({ ...form, project_group: val[0], project: undefined });
                            }} />
                        </div>
                    </div>

                    <div className={'d-flex align-items-center w-100 justify-content-between mt-4'}>
                        <div style={{ flexBasis: '100%' }}>
                            <Label>Proyecto</Label>
                            <Select value={form['project']} options={projects?.map((x) => {
                                return {
                                    value: x.id,
                                    name: x.name ?? ''
                                }
                            })} onChange={(val) => {
                                setForm({ ...form, project: val[0] })
                            }} />
                        </div>
                    </div>

                    <div className={'d-flex align-items-center w-100 justify-content-between mt-4'}>
                        <div style={{ flexBasis: '100%' }}>
                            <Label>Rango de Fecha</Label>
                            <div className={dashboardStyles.dateFilter} style={{
                                marginBottom: '10px',
                            }}>
                                <DatePicker
                                    selectsRange={true}
                                    startDate={form['start_date']}
                                    endDate={form['end_date']}
                                    locale="es"
                                    dateFormat={'dd/MM/yyyy'}
                                    onChange={(dates) => {
                                        setForm({
                                            start_date: dates[0],
                                            end_date: dates[1]
                                        });
                                    }
                                    }
                                    className={dashboardStyles.datePicker}
                                />
                            </div>
                        </div>
                    </div>
                    <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', marginTop: '20px' }}>
                        <div className={'d-flex align-items-center w-100 justify-content-end mt-3'}>
                            <div className={styles.button} onClick={submit}>
                                <FontAwesomeIcon icon={faFile} color={'#52A035'} />
                                <div className={'ps-2'}>Descargar Reporte</div>
                            </div>
                        </div>
                    </div>
                </Modal>
            }
        </div >
    )
}