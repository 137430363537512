export function currentDateStr() {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}
export function dateToStr(pickedDate) {
    console.log('pickedDate', pickedDate);
    const year = pickedDate.getFullYear();
    const month = String(pickedDate.getMonth() + 1).padStart(2, '0');
    const day = String(pickedDate.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

export function timestampToStr(timestamp) {
    return dateToStr(new Date(timestamp));
}

export function minutesToStr(minutes) {
    const h = Math.floor(minutes / 60);
    const m = minutes % 60;
    return `${h}h ${m < 10 ? '0' : ''}${m}m`;
}
export function numToTimePicker(hours) {
    const h = String(Math.floor(hours)).padStart(2, '0');
    const m = String(Math.round((hours - Math.floor(hours)) * 60)).padStart(2, '0');
    return `${h}:${m}`;
}

export function timePickerToNum(time) {
    const [h, m] = time.split(':');
    return parseInt(h) + parseInt(m) / 60;
}